import React from "react";
import Hero from "../../components/hero";
import SectionSeparator from "../../components/sectionSeparator";
import useGetImage from "./useGetImage";
import Article from "./components/article";
import s from "./FarmHistory.module.scss";
const FarmHistory = () => {
    const { hero } = useGetImage();
    return (<>
      <Hero imgURL={hero.childImageSharp.fluid}/>
      <SectionSeparator />
      <div className={s.wrap}>
        <div className={s.container}>
          <Article />
        </div>
      </div>
    </>);
};
export default FarmHistory;
