import { useStaticQuery, graphql } from "gatsby";
const useGetImages = () => {
    const { imageParagraphOne, imageParagraphTwo, imageParagraphThree, imageParagraphFour, imageParagraphFive, imageParagraphSix } = useStaticQuery(graphql `
    query {
      imageParagraphOne: file(
        relativePath: { eq: "images/farm_history/articles/2-400x516.jpg" }
      ) {
        publicURL
      }
      imageParagraphTwo: file(
        relativePath: { eq: "images/farm_history/articles/1.jpg" }
      ) {
        publicURL
      }
      imageParagraphThree: file(
        relativePath: { eq: "images/home_hero/HomepageHeader.jpg" }
      ) {
        publicURL
      }
      imageParagraphFour: file(
        relativePath: { eq: "images/hop_varieties/Hops-for-sale.jpg" }
      ) {
        publicURL
      }
      imageParagraphFive: file(
        relativePath: { eq: "images/farm_history/articles/stewardship.png" }
      ) {
        publicURL
      }
      imageParagraphSix: file(
        relativePath: { eq: "images/farm_history/articles/water.png" }
      ) {
        publicURL
      }
    }
  `);
    return {
        imageParagraphOne: imageParagraphOne.publicURL,
        imageParagraphTwo: imageParagraphTwo.publicURL,
        imageParagraphThree: imageParagraphThree.publicURL,
        imageParagraphFour: imageParagraphFour.publicURL,
        imageParagraphFive: imageParagraphFive.publicURL,
        imageParagraphSix: imageParagraphSix.publicURL,
    };
};
export default useGetImages;
