import React from "react";
import FarmHistory from "../scenes/farmHistory";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={"Farm History"} keywords={keywords}/>
      <FarmHistory />
    </>);
};
