import React from "react";
import s from "./Article.module.scss";
import useGetImages from "./useGetImages";
const Article = () => {
    const { imageParagraphOne, imageParagraphTwo, imageParagraphThree, imageParagraphFour, imageParagraphFive, imageParagraphSix, } = useGetImages();
    return (<div className={s.box}>
      <h2 className={s.title}>BENEFITTING FROM GENERATIONAL KNOWLEDGE</h2>
      <div className={s.alingTextAndImageEven}>
        <p className={s.text}>
          Virgil Gamache Farms, Inc. is fourth generation organically oriented
          hop farm in the heart of the Yakima Valley. We began farming in the
          Yakima Valley in 1913. The first version of the farm was named
          Sunshine Ranch started by the patriarch Albert Gamache. It was on this
          land, that the company’s namesake, Virgil W. Gamache spent his
          formative years. The family raised alfalfa, wheat, corn, potatoes,
          apples and juice grapes.
        </p>
        <figure style={{ textAlign: "center" }}>
          <img src={imageParagraphOne} alt="Albert Gamache" className={s.imageW}/>
          <figcaption>Albert Gamache, in the foreground circa 1934</figcaption>
        </figure>
      </div>
      <div className={s.alingTextAndImage}>
        <figure style={{ textAlign: "center" }}>
          <img src={imageParagraphTwo} alt="Virgil Gamache" className={s.imageW}/>
          <figcaption>Virgil Gamache circa 2005</figcaption>
        </figure>
        <p className={s.text}>
          With the end of Prohibition in 1932, the Gamaches lost no time in
          planting hops. By the mid 1940’s, Virgil and his brother Francis took
          over the farming duties from their father Albert. Virgil eventually
          became the sole proprietor and passed the farm along to his sons. It
          was renamed Virgil Gamache Farms, Inc in his honor. The family has
          continued to refine the techniques that define hop growing, adopting
          new technologies without forgetting the artistic methods of the past.
          In fact, some of the fields that were planted back in 1932 are still
          in production today.
        </p>
      </div>
      <h2 className={s.title}>A STARTLING DISCOVERY</h2>
      <div className={s.alingTextAndImageEven}>
        <p className={s.text}>
          A few years after Virgil retired and passed the farm onto his sons, a
          discovery was made. An unusual hop was found growing out in a hop
          field. It was significantly different than anything that had been
          grown on the farm to date. The hop was designated VG (Virgil Gamache);
          XP(Experimental); 01 (Number One). Although retired from the
          day-to-day operations of the farm, Virgil witnessed the beginnings of
          an entirely new chapter for the farm.
        </p>
        <img src={imageParagraphThree} alt="Virgil Gamache" className={s.imageW}/>
      </div>
      <p className={s.text}>
        The family began to market the carefully cultivated, VGXP01 hops as
        “Amarillo® brand.” The hop started off slowly at first, with just a few
        pounds being trialed here and there and gradually grew until twenty-five
        years later, Amarillo® hops are a popular and well-known aroma variety
        used around the world. Amarillo® brand hops are widely acclaimed by
        craft brew enthusiasts for its unique aromatic properties.
      </p>
      <h2 className={s.title}>A GLOBAL CALLING</h2>
      <div className={s.alingTextAndImage}>
        <img src={imageParagraphFour} alt="Virgil Gamache" className={s.imageW}/>
        <p className={s.text}>
          Answering the call of the craft brewing industry, VGF’s operations
          have not only grown in scale and geographic area, but also in the
          adoption of novel technologies and practices. Originally VGF hops were
          all cultivated at Sunshine Ranch. Today, VGF’s auxiliary production
          program produces under license, a significant volume of Amarillo®
          brand hops outside of the home operation. VGF partner farms grow
          Amarillo® across the Pacific Northwest; in Washington, Oregon and
          Idaho as well as multiple regions in Germany. VGF’s in house, quality
          control laboratory Hoptechnic® monitors hop quality to assure the
          ideal harvest time and aroma profile. Thus, today’s VGF continues to
          perfect the art of producing great hops for great brewers.
        </p>
      </div>
      <h2 className={s.title}>HOP FARM BEST PRACTICES</h2>
      <div className={s.alingTextAndImageEven}>
        <p className={s.text}>
          The Gamache family have a love for the Pacific Northwest. It began
          when great grandfather Albert Gamache first settled in the Yakima
          Valley in 1890’s. That love of the land is demonstrated in the sense
          of stewardship that guides the day-to-day culture at Virgil Gamache
          Farms.
        </p>
        <img src={imageParagraphFive} alt="Virgil Gamache" className={s.imageW}/>
      </div>
      <p className={s.text}>
        One facet of stewardship is through sustainable farming. Our best
        practices combine traditional organic principles including composting
        and planting triticale as well as more advanced principles such as using
        rosemary essential oils to deter pests and citrus oils to reduce weed
        growth. VGF has also implemented the novel use of drones to introduce
        beneficial insects to reduce pest pressure.
      </p>
      <p className={s.text}>
        Virgil Gamache Farms, Inc. makes a continued effort to improve in the
        areas of increasing biodiversity, enhancing good agricultural practices,
        and upholding food safety standards via the implementation of programs
        such as Global G.A.P., Salmon-Safe and HopTechnic’s quality assurance
        program. VGF operates with the health, safety, and welfare of both the
        employee & customer in mind.
      </p>
      <h2 className={s.title}>WATER AND RESOURCE CONSERVATION</h2>
      <div className={s.alingTextAndImage}>
        <img src={imageParagraphSix} alt="Virgil Gamache" className={s.imageW}/>
        <p className={s.text}>
          Living in the semi-arid desert of the Yakima Valley, the Gamache
          family understands that water is a precious resource. The farm has
          made water conservation gains by converting from rill irrigation to
          highly efficient drip irrigation systems. We have a highly motivated
          team of individuals who inspect the irrigation systems daily looking
          for wildlife or mechanical damages and monitoring water quality. This
          allows us to direct the limited water resources specifically where
          needed and in the right amounts. VGF takes care to recycle everything,
          from damaged hop poles, stretched trellis wires, motor oils and
          consumer-grade cardboard waste. All these practices work to uphold the
          conservational principles of our namesake Virgil Gamache.
        </p>
      </div>
    </div>);
};
export default Article;
